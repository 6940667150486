import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import ClickIcon from "../images/svg/click.svg"
import LoadingIcon from "../images/svg/loading.svg"
import {
  Color,
  DefaultLoadingManager,
  LoadingManager,
  PerspectiveCamera,
  PMREMGenerator,
  Scene,
  sRGBEncoding,
  WebGLRenderer,
  AmbientLight,
  AnimationMixer,
  Clock,
  LoopOnce,
} from "three"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js"
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js"
import { OutlineEffect } from "three/examples/jsm/effects/OutlineEffect.js"
import Layout from "../components/layout"

const AllOres: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (typeof document !== "undefined") {
      // params
      const params = {
        toneMappingExposure: 1.5,
        color: {
          bg: 0xff0000,
        },
        fog: {
          active: true,
          near: 0,
          far: 280,
        },
      }

      const canvasParent = document.querySelector(".canvas-parent")

      // Set up the three.js scene
      const scene = new Scene()
      scene.background = new Color(params.color.bg)
      let camera = new PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.005,
        2000
      )
      camera.position.set(
        // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
        -0.45232155557794365,
        1.0001297041636836,
        0.6570104727772331
      )
      camera.rotation.set(
        -1.386258294965543,
        1.4540437992060982,
        1.385022186788755
      )
      let object
      // manager
      const loadModel = () => {
        DefaultLoadingManager.onLoad = () => {
          pmremGenerator.dispose()
        }
      }

      const manager = new LoadingManager(loadModel)

      let action
      // Initialize loaders
      const loader = new GLTFLoader(manager)
      const dracoLoader = new DRACOLoader()
      // dracoLoader.setDecoderConfig({ type: 'js' });
      dracoLoader.setDecoderPath(
        "https://unpkg.com/three@0.148.0/examples/jsm/libs/draco/"
      )
      loader.setDRACOLoader(dracoLoader)
      scene.add(new AmbientLight(0x888888))
      let mixer
      // Wait for both assets to load to hide the loading text and initialize controls
      Promise.all([loader.loadAsync("/three-d-files/laptop.glb")])
        .then(assets => {
          const [model] = assets
          object = model.scene
          const camera1 = model.cameras[0]
          camera = camera1
          //  camera.near=camera1.near
          //  camera.fov=camera1.fov
          //  camera.parent=camera1.parent

          //  camera.position.set(-0.039393368911784445, 7.373747464914949, 0.2936904916955717)
          // //  camera.rotation.set( -1.6666567315440977, -0.0522988783913215, -0.005026470227691831)

          // //  camera.rotation.copy(camera1.rotation)

          // //  camera.quaternion.copy(camera1.quaternion)
          //  camera.scale.copy(camera1.scale)
          //  camera.matrix.copy(camera1.matrix)
          //  camera.matrixWorld.copy(camera1.matrixWorld)
          //  camera.matrixWorldInverse.copy(camera1.matrixWorldInverse)

          camera.aspect = window.innerWidth / window.innerHeight
          camera.updateProjectionMatrix()
          glitchPass = new GlitchPass()
          glitchPass.goWild = true

          composer.addPass(new RenderPass(scene, camera))

          glitchPass.renderToScreen = true

          composer.addPass(glitchPass)

          mixer = new AnimationMixer(model.scene)
          action = mixer.clipAction(model.animations[0])

          action.getMixer().addEventListener("finished", async () => {
            await navigate("/all-cards/")
          })

          action.play()
          action.setLoop(LoopOnce)
          action.clampWhenFinished = true
          action.enable = true

          scene.add(object)

          setIsLoading(false)

          // initializeControls(camera)
        })
        .catch(e => console.log(e))

      const renderer = new WebGLRenderer({ alpha: true, antialias: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.outputEncoding = sRGBEncoding
      canvasParent.appendChild(renderer.domElement)

      const effect = new OutlineEffect(renderer)
      const pmremGenerator = new PMREMGenerator(renderer)

      pmremGenerator.compileEquirectangularShader()
      new EXRLoader().load("/three-d-files/hdri.exr", () => {
        pmremGenerator.dispose()
      })

      let glitchPass

      const composer = new EffectComposer(renderer)

      let renderGli = true

      const onWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()

        renderer.setSize(window.innerWidth, window.innerHeight)
      }

      window.addEventListener("resize", onWindowResize, false)
      let i = 0
      let isclicked = false

      const isclickedfun = () => {
        isclicked = true
        setIsPlaying(true)
      }

      window.addEventListener("click", isclickedfun)
      const clock = new Clock()

      const render = () => {
        if (camera) {
          effect.render(scene, camera)
        }

        window.requestAnimationFrame(render)

        const delta = clock.getDelta()
        if (mixer && isclicked) {
          mixer.update(delta)
        }

        if (action && !action.isRunning() && renderGli) {
          camera.updateMatrixWorld()
          composer.render()
          window.setTimeout(() => {
            renderGli = false
          }, 100)
        }

        if (object && i < 1) {
          // tweenpos.start()
          // tweenrot.start()
          i++
        }
      }

      render()
    }
  }, [])

  return (
    <Layout bgColor="red">
      <SEO title="All Cards" />
      <div className="scene">
        <div className="canvas-parent cursor-pointer">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {isLoading ? (
              <LoadingIcon className="h-[40px] w-[40px] animate-spin text-white" />
            ) : (
              <ClickIcon
                className={`h-[40px] w-[40px] text-white transition-opacity duration-300 ease-in-out ${
                  isPlaying ? "opacity-0" : "opacity-100"
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AllOres
